<template lang="pug">
  div
    div.text-center(v-if="falseInfo")
      .my-5 情報の取得に失敗しました
      buttom.my-2(@click.prevent="$router.go(-1)" style="cursor: pointer; color:blue") 前のページに戻る
    #rocky-full-img
      //-岩場画像
      img(v-bind:src="rockyImgPath" alt="")
      #info
        //-岩場名、住所、レベル
        #rocky-info(v-bind:class="{rockyInfoMove:!isCheckinUserListShow}")
          #nameKana {{nameKana}}
          #name <b>{{rockyName}}</b>
          #address {{address}}
          #level 
            span(v-html="rockyLevelB")
            span(v-html="rockyLevelR")
        //-チェックイン中のユーザー(存在する場合のみ表示)
        #checkin-info(v-if="isCheckinUserListShow")
          #checkin-info-inner
            #checkin 本日<span>{{checkInCount}}人</span>がチェックインしました！
            #member
              .checkin-user-link(v-for="user in this.checkInUserList" @click="showUserPage(user)" v-bind:class="{nameUnderLineNone:user.isPrivate}") 
                |{{user.name}} さん
            #more.text-right
              #checkin-other-user(@click="showRockyCheckinUserList()") <small>もっと見る</small>
    .container-fluid.wrapper(v-if="!falseInfo")
      #share.d-flex.justify-content-end
        a(:href="getLink('facebook')" target="_blank" rel="noopener")
          img(src="../../assets/Facebook_logo.png" alt="")
        a(:href="getLink('twitter')" target="_blank" rel="noopener")
          img(src="../../assets/Twitter_logo.png" alt="")
        a(:href="getLink('line')" target="_blank" rel="noopener")
          img(src="../../assets/Line_logo.png" alt="")
      div(v-if="!isRockImgListContainerLoading")
        //-岩場の特記事項
        #rock-list-wrapper
          #info-container(v-if="isShowInfo")
            .info(v-for="info in this.remarkList")
              i.fas.fa-info-circle 
              |{{info}}
      //- Map
      .spinner
        b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isMapComponentLoading")
      #map-container.mt-3(v-if="!isMapComponentLoading")
        #rocky-map-inner.position-relative
          Map(ref="map" 
              :markerListFromParent= "markerInfo", 
              :zoom=10, 
              :gestureHandling = "gestureHandling",
              :lat="Number(mapCenterLat)" , 
              :lng="Number(mapCenterLng)",
              :isSearchCtlShow = "false",
              :isCsSpaCtlShow = "true",  
              :isRockyInfo = "true",
              :isRockInfo = "false",
              v-if="!isMapComponentLoading")
      //-グラフ
      .full-width-wrapper(v-if="isLevelChartShow")
        //-レベルグラフ(横棒グラフ)
        #level-horz-chart-box
          .levelChart(v-bind:key="currentWidthType")
            HorizontalBarChart(:data="horizontalChartData" :options="horizontalChartOptions",:height="300")
        //-レベルグラフ(縦棒グラフ)
        #level-chart-box
          .levelChart(v-bind:key="currentWidthType")
            Chart(:chartData="levelChartData",:options="levelChartOptions",:height="200")
      //- 岩一覧表示
      .spinner
        b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isRockyInfoContainerLoading")
      #rocky-info-container(v-if="!isRockyInfoContainerLoading")
          //-ユーザー記録へのリンク
          .col-lg-12.mt-3
            .create-record-row
              .linkExist(v-if="checkinHistoryExist" @click="callRouterPushCheckin()") この岩場のチェックイン履歴
            .create-record-row
              .linkExist(v-if="recordExist" @click="callRouterPushRecord()") この岩場の過去の記録
            .create-record-row
              router-link(:to="{ name: 'record_edit', query: {ref: 'rockyInfo', rockyId: this.rockyId}}") 
                b-button(variant="info" size="sm") 
                  span
                    i.fas.fa-edit
                    |この岩場の記録を作成する

      .spinner
        b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isRockImgListContainerLoading")
      div(v-if="!isRockImgListContainerLoading")
        //-岩の写真リスト
        .full-width-wrapper
          .headline 岩のルート情報を見る
        .row
          .col-6.col-md-4(v-for="rock in this.rockList")
            .rock-img-list-box(v-bind:class='{isNotIEJustifyContentCenter:isNotIE}')
              .rock-img-list-box-innner(v-bind:class='{isNotIEJustifyContentCenter:isNotIE}' @click="callRouteList(rock.id,rock.name)")
                img(v-bind:src="createImgPath(rock.imgList[0].path)" v-bind:alt="rock.name")                
                .rocky-ban(v-if="rock.banFlg==1") 登攀禁止
                .rock-name {{rock.name}} 
                  .level {{rock.blLevel}}
                  .level {{rock.rcLevel}}
                .rock-checkin(v-if="rock.checkinCount > 0") 本日<span>{{rock.checkinCount}}人</span>がチェックイン
      .spinner
        b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isGraphContainerLoading")
        //-グラフ
      .full-width-wrapper(v-if="!isGraphContainerLoading")
        .headline.mt-3 直近1ヶ月のチェックイン状況
        .chart(v-if="isChartShow")
          Chart(:chartData="chartData",:options="options",:height="200")
        .chart-data-not-exist(v-else) {{chartMessage}}
      FeedBack
</template>


<script>
import Map from '@/components/Map.vue'
import FeedBack from '@/components/feed_back/FeedBackLink.vue'
import Chart from '@/components/chart/bar_chart.vue'
import HorizontalBarChart from '@/components/chart/horizontal_bar_chart.vue'

import axios from 'axios'

export default {
  // データオブジェクト
  data() {
    return {
      //デフォルト値
      //画面内で共通使用
      isLogin:false,
      rockyId:null,
      rockId:null,
      //Map上に岩マーカーを表示している場合true
      isRockMakerShowOnMap:false,
      //記録、チェックイン履歴へのリンク
      checkinHistoryExist:false,
      recordExist:false,
      //rockyIdの岩画像一覧の表示フラグ
      isRockyImgShow:true,
      //MAP表示
      //岩場アイコン表示用
      rockyLngLat:null,
      mapCenterLat:null,
      mapCenterLng:null,
      //MAPコンポーネント引き渡し用
      markerInfo:null,
      rockyInfoApiData:null,
      gestureHandling:'greedy',
      //岩画面表示
      rockyName:'',
      nameKana:'',
      address:null,
      level:null,
      csList:[],
      spaList:[],
      rockList:null,
      rockyInfo:null,
      rockyImgPath:null,
      remarkList:[],
      isShowInfo:false,
      //rocky-InfoAPIの戻り値を保持
      rockyDetailJson:null,
      //チェックイン中ユーザーのリスト
      apiCheckInData:null,
      apiCheckInUserList:[],
      checkInUserList:[],
      checkInCount:0,
      checkInOtherCount:0,
      checkinOtherUserMessage:'',
      isCheckinUserListShow:false,
      //本日の岩別のチェックイン数
      checkInAggregateList:[],
      //岩場のレベル
      rockyLevelB:'',
      rockyLevelR:'',
      //レベルのグラフ
      isLevelChartShow:false,
      levelChartData:{},
      levelChartOptions:{},
      horizontalChartData:{},
      horizontalChartOptions:{},
      levelChartMessage:'',
      //グラフ
      isChartShow:false,
      chartData:{},
      options:{},
      chartMessage:'',
      //cssの制御に使用
      isNotIE:true,
      //Loading表示用
      isMapComponentLoading:true,
      isRockyInfoContainerLoading:true,
      isRockImgListContainerLoading:true,
      isGraphContainerLoading:true,
      falseInfo: false,
      //windowの横幅
      width: window.innerWidth,
      currentWidthType: 'start'
    }
  },
  // 算出プロパティ
  computed: {
  },
  watch: {
    '$route' (to, from) {
      // console.log('岩場画面のwatch')

    },
  },
  // インスタンス作成後
  created() {

    //IE11でobject-fitを利用できるようにする為に、ofi.jsを呼び出す
    objectFitImages();

    //cssの制御の為にブラウザ判定を行う
    const ua = navigator.userAgent;
    if(ua.indexOf('Trident') !== -1) {
      //IE 11の場合
      this.isNotIE = false
    }

    // インスタンスを作成した後、イベントリスナに登録
    window.addEventListener('resize', this.setChartDisplay, false);

    //岩場情報を取得する
    this.createdProcess()

  },
  beforeDestroy: function () {
    // インスタンスを破棄する前に、イベントリスナから削除
    window.removeEventListener('resize', this.setChartDisplay, false);
  },
  // メソッド
  methods: {

    // 無くても良いが lodash の debounce で発火頻度を調整してあげるとエコ
    setChartDisplay(){
      let horzChartBox = document.getElementById('level-horz-chart-box')
      let chartBox = document.getElementById('level-chart-box')

      //画面サイズにより表示するグラフ種類をかえる
      if(window.innerWidth > 992 ){
        //PC表示
        if(horzChartBox != null){
          chartBox.style.display = 'block'
          horzChartBox.style.display = 'none'
          this.width = 'PC'
          this.currentWidthType = 'PC'
        }
      }else{
        //モバイル
        if(chartBox != null){
          chartBox.style.display = 'none'
          horzChartBox.style.display = 'block'
          this.width = 'モバイル'      
          this.currentWidthType = 'mobile'
        }
      }
    },

    //------------------------
    //最近見た岩場のデータ更新
    //------------------------
    async pushRecentRocky(){
      //未ログイン時は何もしない
      if(!this.$user.isLogin){
        return
      }
      //API実行
      const url = process.env.VUE_APP_API_GATEWAY + '/api-user-info'
      const params = {
        rockyId : this.rockyId,
        resetFlg : false
      }
      //更新実行
      try {
        await axios.patch(url, params, {
          headers: {
            'Authorization': this.$user.idToken
          }
        })
      } catch(err) {
        //TODO:エラー処理
        // alert('岩場参照履歴を更新できませんでした。')
        return
      }

    },
    //-------------------------------------------------
    // created時の処理
    // 岩場IDから対象の岩場情報を取得して表示する
    //-------------------------------------------------
    async createdProcess(){

      this.rockyId = this.$route.params.rockyId
      this.isLogin = this.$user.isLogin

      //ログインしている場合、岩場に対する記録とチェックイン履歴の有無を取得する
      if(this.isLogin){
        await this.setRecordAndCheckinHistoryExists(this.rockyId)
      }

      //岩場のチェックイン数(グラフ用データ)を取得する
      this.setChartData()
      //チェックイン中のユーザーを取得する(後続処理でデータを使う為awaitする)
      await this.setCheckingInData()

      //API実行
      await this.getRockyMasterData(this.rockyId,this.isLogin)
      .then(response => {
        this.rockyInfoApiData = response.data
      })
      .catch(function (error) {
        this.falseInfo = true
      })

      //データが0件の場合、検索画面に戻る
      if(this.rockyInfoApiData.length == 0){
        await this.asyncDialog('該当する岩場は存在しません。')
        this.$router.push('/')
      }
      console.log(this.rockyInfoApiData)
      //取得した値をセットする
      let rockyDataRecord = this.rockyInfoApiData[0]
      this.rockyId = rockyDataRecord.rockyId
      this.rockyName = rockyDataRecord.rockyName
      this.nameKana = rockyDataRecord.rockyNameKana
      this.address = rockyDataRecord.rockyInfo.address
      this.csList = rockyDataRecord.rockyInfo.csList
      this.spaList = rockyDataRecord.rockyInfo.spaList
      this.rockList = this.setRockName(rockyDataRecord.rockList)
      //岩に対する処理
      this.rockList.forEach((rock) => {
        //特記事項をListにセットする
        if(rock.dbComment != null){
          this.remarkList.push(rock.dbComment)
          this.isShowInfo = true
        }
        //岩のチェックイン数をセットする
        rock['checkinCount'] = this.getRockCheckInCount(rock.id)
      })

      this.rockyInfo =rockyDataRecord.rockyInfo
      this.rockyImgPath = process.env.VUE_APP_MATERIAL_URL + rockyDataRecord.imgList[0].path
      this.rockyLngLat = this.rockyInfo.longitudelatitude
      this.mapCenterLat = this.getLatLng(this.rockyLngLat)[0]
      this.mapCenterLng = this.getLatLng(this.rockyLngLat)[1]
      // 以降の処理で使用できるよう保持しておく
      this.rockyDetailJson = rockyDataRecord
      // Mapに表示するマーカー情報Listを作成する
      let wkList = []
      
      let wkObj = {
        no:'',
        name: this.rockyName,
        longitudelatitude: this.rockyLngLat,
        address: this.rockyInfo['address'],
        url: this.rockyId 
      }
      wkList.push(wkObj)

      this.markerInfo = wkList

      //MAPのLoading解除
      this.isMapComponentLoading = false
      this.isRockyInfoContainerLoading = false

      //レベルのグラフデータをセットする
      await this.setLevelChartData()

      //岩の特記事項、岩画像Listを描画する
      this.isRockImgListContainerLoading = false

      //最近見た岩場のデータ更新する
      this.pushRecentRocky()

      //ページtitleを岩場名にする。(seo対策)
      document.title = this.rockyName + ' | ' + process.env.VUE_APP_TITLE

    },
    //---------------------
    //岩の名前に連番を付ける
    //---------------------
    setRockName(rockList){
      let count = 0
      rockList.forEach((rock) => {
        count = count + 1
        let cntStr = count + '．'
        if(rock.name == null){
          rock.name = cntStr + '（岩名なし）'
        }else{
          rock.name = cntStr + rock.name          
        }
      })
      return rockList
    },
    //--------------------------
    //岩のチェックイン数を取得する
    //--------------------------
    getRockCheckInCount(rockId){
      //岩IDに紐付くチェックイン数を取得する
      let targetRockList = this.checkInAggregateList.filter(record => record.rockId == rockId)
      if(targetRockList.length > 0){
        //当日チェックインしたユーザーが存在する場合
        return targetRockList[0].count
      }
      return 0
    },
    //---------------
    // 岩場データ取得
    //---------------
    getRockyMasterData: (paramRockyId,isLogin) => new Promise((resolve, reject) => {
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-rocky/rocky-info?rockyId=' + paramRockyId + '&isLogin=' + isLogin
      axios.get(jsonUrl)
      .then(res => resolve(res))
      .catch(err => reject(err))
    }),
    //---------------
    // ダイアログ表示
    //---------------
    asyncDialog(msg) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk(msg, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
        .catch(err => {
          // An error occurred
        })
      })
    },
    //---------------------------------------------
    //レベルのグラフのデータを取得してセットする
    //---------------------------------------------
    async setLevelChartData(){
      //APIを実行してデータ取得
      let rockyGradeObj = await this.getRockyGrade(this.rockyId)

      //データが存在しない場合
      if(rockyGradeObj.length == 0){
        //エラー処理
        return
      }

      //岩場全体のレベル
      let rockyGradeRange = rockyGradeObj[0].rockyGradeRange
      let rockyBlMin = rockyGradeRange.bl.min == null ? '' : rockyGradeRange.bl.min
      let rockyBlMax = rockyGradeRange.bl.max == null ? '' : rockyGradeRange.bl.max
      let rockyRcMin = rockyGradeRange.rc.min == null ? '' : rockyGradeRange.rc.min
      let rockyRcMax = rockyGradeRange.rc.max == null ? '' : rockyGradeRange.rc.max

      //岩のレベル
      let wkRockyLevelList = rockyGradeObj[0].rockyLevelList
      //岩単位のレベル
      let wkRockLevelList = rockyGradeObj[0].rockList
      let blCnt = 0
      let rcCnt = 0
      //岩場のルート本数を算出
      wkRockyLevelList.forEach((item) => {
        blCnt += item.bl
        rcCnt += item.rc
      })

      let mark = '' 

      //岩場のレベル
      if(blCnt > 0){
        this.rockyLevelB = 'ボルダー：'  + blCnt + '本'
        mark = (rockyBlMin==null && rockyBlMax==null) ? '' :'～'
        this.rockyLevelB = this.rockyLevelB +'（' + rockyBlMin + mark + rockyBlMax + '）' + '<br>'
      }

      if(rcCnt > 0){
        this.rockyLevelR = 'リード：' + rcCnt + '本'
        mark = (rockyRcMin==null && rockyRcMax==null) ? '' :'～'
        this.rockyLevelR = this.rockyLevelR + '（' + rockyRcMin + mark + rockyRcMax + '）'
      }

      //岩のレベル
      this.rockList.forEach((item) => {
        let targetRock = wkRockLevelList.filter(record => record.rockId == item.id)[0]
        let rockBlCnt = targetRock.bl.cnt
        let rockBlMin = targetRock.bl.min == null ? '' : targetRock.bl.min
        let rockBlMax = targetRock.bl.max == null ? '' : targetRock.bl.max
        let rockRcCnt = targetRock.rc.cnt
        let rockRcMin = targetRock.rc.min == null ? '' : targetRock.rc.min
        let rockRcMax = targetRock.rc.max == null ? '' : targetRock.rc.max

        item.blLevel = ''
        if(rockBlCnt > 0){
          item.blLevel = 'ボルダー：' + rockBlCnt + '本 '
          if(rockBlMin == rockBlMax){
            item.blLevel = item.blLevel + '（' + rockBlMin + '）'
          }else{
            mark = (rockBlMin=='' && rockBlMax=='') ? '' :'～'
            item.blLevel = item.blLevel + '（' + rockBlMin + mark + rockBlMax + '）'
          }
        }
    
        if(rockRcCnt > 0){
          item.rcLevel = 'リード：' + rockRcCnt + '本 '
          if(rockRcMin == rockRcMax){
            item.rcLevel = item.rcLevel + '（' + rockRcMin + '）'
          }else{
            mark = (rockRcMin=='' && rockRcMax=='') ? '' :'～'
            item.rcLevel = item.rcLevel + '（' + rockRcMin + mark + rockRcMax + '）'
          }
        }
      })

      //------------------------
      //縦棒グラフのオプション
      //------------------------
      this.levelChartOptions = {
        responsive: true, //レスポンシブ
        maintainAspectRatio: false, //サイズ変更の際に、元のキャンバスのアスペクト比を維持するかどうか
        animation: false, //アニメーション
        legend: {           //凡例設定
          display: true,  //表示設定
          labels: {
            fontColor: '#cfd3d7'
          }
        },
        plugins: {
          //棒グラフ上に表示するデータ値
          datalabels: {
            align: 'center',
            color: '#adb4b9',
          }
        },
        title: {            //タイトル設定
            display: false, //表示設定
            fontSize: 18,   //フォントサイズ
            text: 'タイトル' //ラベル
        },
        scales: {                          
          //軸設定
          yAxes: [{
            display: false,
            scaleLabel: {
              display: false,
              labelString: '',
              fontSize: 10
            },
            ticks: {
              max: 20,
              min: 0,
              stepSize: 2
            },
          }],
          xAxes: [{
            display: true,
            stacked: false,
            gridLines: {
              display: false
            },
            ticks: {
              fontColor: "#86898d",
            },
            scaleLabel: {
              display: false,
              labelString: '',  //ラベル
              fontSize: 10
            },
          }]
        },
        layout: {         //レイアウト
          padding: {    //余白設定
            left: 30,
            right: 30,
            top: 10,
            bottom: 0
          }
        }
      }
      
      //------------------------
      //横棒グラフ表示のオプション
      //------------------------
      this.horizontalChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        legend: {
          display: true,
          labels: {
            fontColor: '#cfd3d7'
          }
        },
        plugins: {
          //データの値を表示
          datalabels: {
            color: '#adb4b9',
            font: {
              size: 10
            },
            anchor: 'end', // データラベルの位置（'end' は上端）
            align: 'end', // データラベルの位置（'end' は上側）
          }
        },
        scales: {                          
          //y軸設定
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: '',
              fontSize: 5
            },
            ticks: {
              max: 20,
              min: 1,
              stepSize: 2,
              fontColor: "#86898d",
            },
          }],
          //x軸設定
          xAxes: [{
            display: false,
            stacked: false,
          }]
        },
        layout: {
          padding: {
            left: 20,
            right: 50,
            top: 10,
            bottom: 20
          }
        }
      }

      //------------------------
      //グラフに表示するデータ
      //------------------------
      //初めに16段階分要素をセットして、取得した値をセットする
      let wkList = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
      wkRockyLevelList.forEach((item) => {
        wkList[item.level-1] = item.bl + item.rc
      })

      let wkDatasets = [
        {
          label: 'ルート数',
          backgroundColor:'#006fbe',
          data: wkList
        }
      ]

      //横棒グラフ用
      this.horizontalChartData = {
        labels : [
          '10級|5.2|5.3|5.4','9級|5.5|5.6',
          '8級|5.7|5.8','7級|5.9|5.10a',
          '6級|5.10b|5.10c','5級|5.10d|5.11a|5.11b',
          '4級|5.11c|5.11d','3級|5.12a|5.12b',
          '2級|5.12c|5.12d','1級|5.13a|5.13b',
          '初段|5.13c|5.13d','二段|5.14a',
          '三段|5.14b','四段|5.14c','五段|5.14d','六段|5.15a'
        ],
        datasets: wkDatasets
      }

      //縦棒グラフ用
      this.levelChartData = {
        labels : [
          ['10級','5.2','5.3','5.4'],['9級','5.5','5.6'],
          ['8級','5.7','5.8'],['7級','5.9','5.10a'],
          ['6級','5.10b','5.10c'],['5級','5.10d','5.11a','5.11b'],
          ['4級','5.11c','5.11d'],['3級','5.12a','5.12b'],
          ['2級','5.12c','5.12d'],['1級','5.13a','5.13b'],
          ['初段','5.13c','5.13d'],['二段','5.14a'],
          ['三段','5.14b'],['四段','5.14c'],['五段','5.14d'],['六段','5.15a']
        ],
        datasets: wkDatasets
      }

      //データのセットが完了したタイミングでグラフ描画する
      this.isLevelChartShow = true
      this.isGraphContainerLoading = false

    },
    //---------------------------------------------
    //グラフのデータを取得してセットする
    //---------------------------------------------
    async setChartData(){
      //APIを実行してデータ取得
      let recentCheckInCountList = await this.getRecentCheckInCount(this.rockyId)

      //グラフに表示するデータが存在しない場合
      if(recentCheckInCountList.length == 0){
        this.isChartShow = false
        this.isGraphContainerLoading = false
        this.chartMessage = 'ここ１ヶ月のヤマラボユーザーのチェックイン記録はありません。'
        return
      }

      //グラフ棒の色
      const CHART_BACKGROUND_WEEKDAY = '#bfc5c9'
      const CHART_BACKGROUND_SATURDAY = '#7dbae5'
      const CHART_BACKGROUND_SUNDAY_HOLIDAY = '#f0b5ba'
      //グラフのラベルを保持するList
      let labelList = []
      //グラフ棒の色を保持するList
      let backgroundColorList = []
      //グラフのデータを保持するList
      let dataList = []
      //APIから取得したデータを処理
      recentCheckInCountList.forEach((item) => {
        //console.log(item.week)
        //ラベル
        labelList.push(item.date+'('+ item.week +')')
        //グラフの棒の色
        let wkBackGroundColor = ''
        if(item.week == '土'){
          backgroundColorList.push(CHART_BACKGROUND_SATURDAY)
        }else if(item.week == '日'){
          backgroundColorList.push(CHART_BACKGROUND_SUNDAY_HOLIDAY)
        }else{
          backgroundColorList.push(CHART_BACKGROUND_WEEKDAY)
        }
        //グラフのデータ
        dataList.push(item.count)
      })

      //画面表示用のobjにセットする
      this.chartData = {
        labels : labelList,
        datasets:[{
          label : 'チェックイン数',
          //グラフの棒の色(可変にする為に、全要素分指定する)
          backgroundColor : backgroundColorList,
          //グラフのデータとなるチェックイン数
          data : dataList
        }]
      }
      //グラフのオプション
      this.options = {
        responsive: true, //レスポンシブ
        maintainAspectRatio: false, //サイズ変更の際に、元のキャンバスのアスペクト比を維持するかどうか
        animation: false, //アニメーション
        legend: {           //凡例設定
            display: false  //表示設定
        },
        title: {            //タイトル設定
            display: false, //表示設定
            fontSize: 18,   //フォントサイズ
            text: 'タイトル' //ラベル
        },
        scales: {                          
            //軸設定
            yAxes: [{          //y軸設定
                display: true, //表示設定
                scaleLabel: {  //軸ラベル設定
                    display: false,                //表示設定
                    labelString: '',  //ラベル
                    fontSize: 10                 //フォントサイズ
                },
                ticks: {           //最大値最小値設定
                    min: 0,        //最小値
                    //max: 20,       //最大値
                    //fontSize: 15,  //フォントサイズ
                    stepSize: 5    //軸間隔
                },
            }],
            // xAxes: [{                         //x軸設定
            //     display: true,                //表示設定
            //     barPercentage: 0.4,           //棒グラフ幅
            //     categoryPercentage: 0.4,      //棒グラフ幅
            //     scaleLabel: {                 //軸ラベル設定
            //         display: false,             //表示設定
            //         labelString: '',  //ラベル
            //         fontSize: 15          //フォントサイズ
            //     },
            //     ticks: {
            //         fontSize: 15          //フォントサイズ
            //     },
            // }],
        },
        layout: {         //レイアウト
            padding: {    //余白設定
                left: 0,
                right: 0,
                top: 10,
                bottom: 0
            }
        }
      }

      //for debag------------------------------------↓↓↓
      // this.chartData = {
      //   labels: ['3/1(月)','2(火)','3(水)','4(木)','5(金)','6(土)','7(日)',
      //         '8(月)','9(火)','10(水)','11(木)','12(金)','13(土)','14(日)',
      //         '15(月)','16(火)','17(水)','18(木)', '19(金)', '20(土)', '21(日)',
      //         '22(月)', '23(火)', '24(水)', '25(木)', '26(金)', '27(土)', '28(日)',
      //         '29(月)', '30(火)', '31(水)'],
      //   datasets: [
      //     {
      //       label: 'チェックイン数',
      //       backgroundColor:['#bfc5c9', '#bfc5c9', '#bfc5c9', '#bfc5c9', '#bfc5c9', '#7dbae5', '#f0b5ba',
      //         '#bfc5c9', '#bfc5c9', '#bfc5c9', '#bfc5c9', '#bfc5c9', '#7dbae5', '#f0b5ba',
      //         '#bfc5c9', '#bfc5c9', '#bfc5c9', '#bfc5c9', '#bfc5c9', '#7dbae5', '#f0b5ba',
      //         '#bfc5c9', '#bfc5c9', '#bfc5c9', '#bfc5c9', '#bfc5c9', '#bfc5c9', '#f0b5ba',
      //         '#bfc5c9', '#bfc5c9', '#bfc5c9'],
      //       data: [10, 10, 2, 1, 1, 0, 5, 
      //             6, 10, 12, 1, 1, 10, 10, 
      //             2, 1, 1, 0, 5, 6, 10,
      //             12, 1, 20, 10, 2, 1, 1, 
      //             0,2,6,2]
      //     }
      //   ]
      // }
      //for debag------------------------------------↑↑↑

      //データのセットが完了したタイミングでグラフ描画する
      this.isChartShow = true
      this.isGraphContainerLoading = false

    },
    //---------------------------------------------
    //岩場のチェックイン数(グラフ用データ)を取得する
    //---------------------------------------------
    getRecentCheckInCount: (rockyId) => new Promise((resolve, reject) => {
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-checkin/recent-checkin-count'
      axios.get(jsonUrl, {
        params: {"rockyId": rockyId},
        headers: {}
      })
      .then(res => resolve(res.data))
      .catch(err => reject(err))
    }),
    //---------------------------------------------
    //岩場のレベル表示用のデータを取得する
    //---------------------------------------------
    getRockyGrade: (rockyId) => new Promise((resolve, reject) => {
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-rocky/rocky-grade'
      axios.get(jsonUrl, {
        params: {"rockyId": rockyId},
        headers: {}
      })
      .then(res => resolve(res.data))
      .catch(err => reject(err))
    }),
    //--------------------------------------------
    // 現在チェックイン中のユーザーを取得してセットする
    //--------------------------------------------
    async setCheckingInData(){

      //APIを実行してthis.apiCheckInDataにデータセット
      await this.getCheckingInUserAndCount(this.rockyId)

      //岩場のチェクイン数  
      this.checkInCount = this.apiCheckInData.rockyCheckInCount
      //チェックイン中のユーザーList
      this.apiCheckInUserList = this.apiCheckInData.checkingInUserList
      //岩別のチェックイン数      
      this.checkInAggregateList = this.apiCheckInData.aggregateList
      
      if(this.apiCheckInUserList.length ==0){
        //チェックイン中のユーザーが存在しない場合
        return
      }
      
      //先頭3ユーザー分のみ画面に表示する
      for(let i=0;  i<3;  i++) {
        if(this.apiCheckInUserList[i] != undefined){
          this.checkInUserList.push(this.apiCheckInUserList[i])
        }
      }

      //表示件数
      if(this.apiCheckInUserList.length > 3){
        this.checkInOtherCount = this.apiCheckInUserList.length - 3
        this.checkinOtherUserMessage = '他'+this.checkInOtherCount+'人がチェックインしています。'
      }else{
        this.checkInOtherCount = 0
        this.checkinOtherUserMessage =  this.checkInCount+'人がチェックインしています。'
      }

      //チェックインユーザー欄を表示する
      this.isCheckinUserListShow = true

    },
    //-----------------------------------
    // 現在チェックイン中のユーザーを取得する
    //-----------------------------------
    async getCheckingInUserAndCount(rockyId){
      let jsonUrl = ''
      if(this.$user.isLogin){
        //ログイン時
        jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-checkin/checkin-count-and-userlist-logined'
      }else{
        //未ログイン時
        jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-checkin/checkin-count-and-userlist-not-logined'
      }

      await axios
        .get(jsonUrl,{
          params: {
            'rockyId': rockyId,
          },         
          headers: {
            'Authorization': this.$user.idToken
          }
        })
        .then(response => {
          this.apiCheckInData = response.data
        })
        .catch(err => {
          this.falseInfo = true
        })
    }, 
    //---------------
    // 経度緯度を分割
    //---------------
    getLatLng(latLng){
      return latLng.split(',');
    },
    //---------------
    // 画像パスを生成
    //---------------
    createImgPath(path){
      return this.getRockThumbPath(path,this.rockyId,'360')
    },
    //-------------------------------------------------------
    // MAPにコンビニと温泉のマーカーを表示(MAPクラスから呼ばれる)
    //--------------------------------------------------------
    addCsSpaOnMap(){
      this.$refs.map.addCsSpaMarker(this.csList,this.spaList)
    },
    //---------------------------------------
    // 岩画像選択時の処理(ルート一覧を表示する)
    //---------------------------------------
    async callRouteList(rockId,rockName){

      //Map上に岩マーカーが表示されていない場合は表示する
      if(!this.isRockMakerShowOnMap){
        //岩マーカーを表示する
        await this.$refs.map.addRockMarker(this.rockyId)
      }

      // 対象のマーカーをアクティブにする
      this.callChangeRockMarkerActive(rockId,rockName)

      this.rockId = rockId
      //ルート一覧のデータ取得とセット
      this.getRouteDataList(rockId)

      this.$router.push({
        name: 'rockyInfo_:rockyId_:rockId',
        params:{
          rockyId:this.rockyId,
          rockId:rockId,
          rockName:rockName,
          rockyDetailJson:this.rockyDetailJson,
        },
      })
    },
    //---------------------------------------
    // 対象の岩場のピンをアクティブにする
    //(この画面と子コンポーネントから呼ばれる)
    //---------------------------------------
    callChangeRockMarkerActive(rockId,rockName){
      this.$refs.map.changeRockMarkerActive(rockId,rockName)
    },
    //---------------------
    // ルート情報の取得処理
    //---------------------
    async getRouteDataList(paramRockId){

      let rockId = null

      if(paramRockId == undefined || paramRockId ==null){
        if(this.$route.params.rockId != undefined){
          rockId = this.$route.params.rockId
        }else if(this.$route.params.rockDataSet.rockId != undefined){
          rockId = this.$route.params.rockDataSet.rockId
        }else if(window.location.hash == '#routeView'){
          //ルート画像画面からブラウザバックされた場合
          rockId = this.rockId
        }
      }else{
        rockId = paramRockId
      }

      //岩情報を保持していない場合は再度取得する
      if(this.rockyDetailJson==null){

        let json = await this.getRockyMasterData(this.rockyId,this.isLogin)
        if (json) {
          if(json.data.length==0){
            //対象のデータが存在しない場合
            this.$router.push('/rockyInfo/' + this.rockyId);  
            //this.dispErrorDialog()
          }
          this.rockyDetailJson = json.data[0]
          this.rockyName = this.rockyDetailJson['rockyName']
        }
      }

      //岩Listから対象の岩の情報を抽出する
      let rockList = this.rockyDetailJson.rockList
      let targetRockData = rockList.find(element => element.id == rockId);
      if(!targetRockData){
        this.$router.push('/rockyInfo/' + this.rockyId);
        //this.dispErrorDialog()
      }

      //岩名
      this.rockName = targetRockData.name
      //岩画像のパス
      this.rockyImgPath = this.getRockThumbPath(targetRockData.imgList[0].path,this.rockyId,'360')

    },
    async dispErrorDialog(){
      await this.asyncDialog('データが存在しません')
    },
    //--------------------------------------------------------------------------
    //岩場に対してログインユーザーの記録とチェックイン履歴が存在するかをどうかを取得する
    //--------------------------------------------------------------------------
    async setRecordAndCheckinHistoryExists(rockyId) {
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-record/record-and-checkin-history-exists'

      await axios.get(jsonUrl,{
        params: {
          "rockyId" : rockyId,
        },
        headers: {
          'Authorization': this.$user.idToken
        }
      }).then(response => {
        this.recordExist = response.data.recordExist
        this.checkinHistoryExist = response.data.checkinExist
      })
      .catch(err => {
        this.falseInfo = true
      })
    },

    //-----------------------------------
    // チェックイン中ユーザー一覧画面に遷移
    //-----------------------------------    
    showRockyCheckinUserList(){
      this.$router.push({ name: 'rockyInfo_:rockyId_checkingin_user_list',
                          params: {
                            checkinUserList: this.apiCheckInUserList, 
                            rockyName: this.rockyName}})
    },
    //------------------------
    // ユーザー情報モーダルOPEN
    //------------------------    
    showUserPage(item){
      this.$router.push('/myPage/' + item.id +'/userInfoTop')
    },
    callRouterPushCheckin(){
      this.$router.push('/myPage/' + this.$user.userId +'/checkInHistoryList')
    },
    callRouterPushRecord(){
      this.$router.push('/myPage/' + this.$user.userId +'/rockList?rockyId=' + this.rockyId)
    },
    getLink(target){
      let path = process.env.VUE_APP_URL + this.$route.path

      if(target=='facebook'){
        return "//www.facebook.com/sharer.php?src=bm&u=" + path + "&t=" + this.rockyName
      }else if(target=='twitter'){
        return "http://twitter.com/share?text=" + this.rockyName + "&url=" + path + "&via=&hashtags=ヤマラボ&related=yamalab"
      }else if(target=='line'){
        return "https://social-plugins.line.me/lineit/share?url=" + path
      }
    }
  },
  // コンポーネント
  components: {
    Map,
    FeedBack,
    Chart,
    HorizontalBarChart
  },
  // マウント後
  mounted() {
    // console.log('岩場画面のmounted')
    this.$nextTick(() => {
      //画面サイズに合わせたグラフを表示する
      this.setChartDisplay()
      // ビュー全体がレンダリングされた後にのみ実行されるコード
      // console.log('岩場画面のnextTick')
    })
  },
  // データ変更後
  updated() {
    // console.log('岩場画面のupdate')
  }
}
</script>

<style lang="stylus" scoped>
#app .wrapper
  padding-top 0px

#rocky-full-img
  width 100%
  height 350px
  position relative
  img
    position absolute
    top 0
    left 0
    width 100%
    height 350px
    object-fit cover
    filter brightness(75%)
  #info
    width 100%
    height 350px
    position absolute
    top 30%
    left 0
    color #fff
    padding 0 1rem
    .rockyInfoMove
      position absolute
      top 25%
      left 5% 
    #rocky-info
      #name
        font-size 1.5rem
      #nameKana
        font-size .9rem
    #checkin-info
      #checkin-info-inner
        margin-top 0.5rem
        padding 0.5rem 1rem
        background-color rgba(0, 0, 0, 0.5)
        font-size 0.8rem
        span
          color #006fbe
        #member
          display flex
          .checkin-user-link
            overflow hidden
            text-overflow ellipsis
            white-space nowrap
            font-size 0.8rem
            padding-top 0.3rem
            text-decoration underline
            cursor pointer
            margin-right 1rem
        #checkin-other-user
          text-decoration underline
          cursor pointer

.create-record-row
  margin-bottom 1rem
  text-align right
  .linkExist
    text-decoration underline
    cursor pointer

.full-width-wrapper
  margin 0 -15px
  .headline
    width 100%
    padding-left 0.5rem
    font-size 1.2rem
    font-weight 600
    color #ffffff
    background #004170

  #level-horz-chart-box
    display block
  #level-chart-box
    display none

  //レベルのグラフ
  .levelChart,.level-chart-data-not-exist
    width 100%
    //グラフの背景色
    background #171D32

  .level-chart-data-not-exist
    padding 1rem
  .levelChart
    margin-top 2rem

  //グラフ
  .chart,.chart-data-not-exist
    width 100%
    //グラフの背景色
    background #eceeef
  .chart-data-not-exist
    padding 1rem
  .chart
    margin-top 1rem

#checkin-container
  margin 0 -15px
  height 170px
  display flex
  color #ffffff
  background #171D32
  #checkin-left
    width 50%
    display flex
    justify-content center
    align-items center
    #checkin-circle
      width 150px
      height 150px
      background #203864
      border-radius 50%
      position relative
      text-align center
      #top,#middle,#bottom
        width 100%
        position absolute
        color #00B0FF
      #top
        top 17px
      #middle
        top 40px
        font-size 1.2rem
        color #dee1e3
        span
          font-size 2rem
      #bottom
        bottom 12px

  #checkin-right
    width 50%
    #checkin-user-list
      height 120px
      .checkin-user-link
        font-size 0.8rem
        padding-top 1rem
        text-decoration underline
        cursor pointer
      .nameUnderLineNone
        text-decoration none
    #checkin-other-user
      height 50px
      text-decoration underline
      cursor pointer
    #checkin-user-not-exist
      padding 3rem 0.5rem 0 0

#map-container
  margin-bottom 1rem
  #rocky-map-inner
    height 300px
    margin-right -15px
    margin-left -15px

.spinner
  text-align center

//岩場画像
#rocky-img
  margin-bottom 1rem
  #rocky-img-innner
    position relative
    img 
      width 100%
      height auto
    #rocky-img-info
      position absolute
      color white
      bottom 0
      width 100%
      height 30%
      background-color rgba(0, 0, 0, 0.5)
      display flex
      #rocky-img-info-left
        width 50%
        #rocky-name
          padding-left 0.3rem
          font-size 1rem
      #rocky-img-info-right
        width 50%
        #address
          font-size 0.8rem
          color #B7C4CF
        #revel
          font-size 0.8rem
          color #00B0FF
#share
  margin 1rem -10px 0 -10px
  height 35px
  position relative
  img
    margin-left 1rem
    width 30px
    height 30px
#rock-list-wrapper
  margin 0 -10px

//特記事項
#info-container
  background #eceeef
  color #df5f6a
  margin 1rem 0
  padding 5px
  border-radius 3px

//岩画像
.col-6,.col-md-4
  padding 5px 5px
.rock-img-list-box
  width 100%
  padding-top 75%
  background-color #374047
  position relative
  //中央寄せ
  //(justify-content centerは.isNotIEJustifyContentCenterで指定)
  display flex
  align-items center
  .rock-img-list-box-innner
    cursor pointer
    //中央寄せ
    //(justify-content centerは.isNotIEJustifyContentCenterで指定)
    display flex
    align-items center
    img
      max-width 100%
      height 100%
      position absolute
      top 0
    .rocky-ban
      position absolute
      color #d2202f
      font-size 1.4rem
      font-weight 600
      left 0
      top 0
      background rgba(222,225,227,0.6)
      padding 2px 0.8rem
    .rock-name
      position absolute
      left 0
      bottom 0
      width 100%
      height 60%
      background-color rgba(0, 0, 0, 0.5)
      color #fff
      font-size 0.8rem
      padding 0.1rem 0 0 0.3rem
      font-size 0.8rem
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      .level
        font-size 10px
        color rgba(255,255,255,0.5)
        padding-left 0.5rem
    .rock-checkin
      position absolute
      bottom 0
      right 5px
      font-size 0.5rem
      color white
      span
        font-size 0.7rem
        color #00B0FF

//IE11以外のブラウザの場合
.isNotIEJustifyContentCenter
  justify-content center //IE11の場合これが効かない対策

// 大デバイス（デスクトップ, 992px 以上）
@media (min-width 992px)
  #app .wrapper
    padding-top -50px
  #rocky-img
    display: flex;
    justify-content: center;
    align-items: center;
    #rocky-img-innner
      position relative
      width 30%

  #checkin-container
    margin-bottom 1rem

  .full-width-wrapper
    #level-horz-chart-box
      display none
    #level-chart-box
      display block

  .rock-img-list-box
    .rock-img-list-box-innner
      .rock-name
        height 40%
      .rock-checkin
        font-size 0.8rem
        span
          font-size 1rem
  #rocky-full-img
    width 100%
    height 350px
    position relative
    img
      position absolute
      top 0
      left 0
      width 100%
      height 350px
      object-fit cover
      font-family 'object-fit: cover;'
      filter brightness(80%)
    #info
      width 100%
      height 130px
      margin-right -15px
      margin-left -15px
      position absolute
      top 45%
      left 0
      display flex
      color #fff
      padding-top 1rem
      #rocky-info
        width 60%
        padding-left 10%
        #name
          font-size 1.5rem
        #nameKana
          font-size .9rem
      #checkin-info
        width 40%
        #checkin-info-inner
          width 82%
          padding 0.5rem 1.5rem
          background-color rgba(0, 0, 0, 0.5)
          span
            color #006fbe
          .checkin-user-link
            font-size 0.9rem
            padding-top 0.3rem
            text-decoration underline
            cursor pointer
          #checkin-other-user
            text-decoration underline
            cursor pointer
</style>