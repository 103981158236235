var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "feed-back" } },
    [
      _c("router-link", { attrs: { to: "/feed_back/input" } }, [
        _vm._v(" 不具合・岩情報を報告する")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }